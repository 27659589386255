import React, { Component } from "react"
import Api from '@aws-amplify/api'
import AppStore from '../services/AppStore'
import Image from './image'
import loadingIcon from '../images/loader.svg'
import BucketService from '../services/BucketService'
import { Link } from "gatsby"
import DisplayService from '../services/DisplayService'

// import inProgressIcon from '../images/in-progress.png'

import "./latest.css"

class Latest extends Component {
    constructor() {
        super()
        this._isMounted = false
        this.state = {
            loading: true,
            itemList: []
        }
    }

    async componentDidMount() {
        this._isMounted = true
        

        let itemList = AppStore.get('LATEST')
        if (!itemList) {
            try {
                let data = await Api.get('GetItemList', '/medias/latest')
                const buckets = await BucketService.getList()
                itemList = []
                let index = 0

                data.medias.forEach(item => {
                    const split = item.key.split('/')
                    itemList.push({
                        id: `latest${index}`,
                        bucket: split[0],
                        key: item.key,
                        created: item.created,
                        height: item.height,
                        width: item.width,
                        mode: 'LATEST',
                        bucketName: buckets.filter(bucket => (bucket.name === split[0]))[0].display,
                        day: split[split.length - 1].substr(0, 8)
                    })
                    index++
                })
                itemList.sort((a,b)=>((a.created < b.created)? 1 : (a.created > b.created)? -1 : 0))
                AppStore.set('LATEST', itemList)
            } catch(error) {
                console.error(error)
            }
        }

        if (this._isMounted) {
            this.setState({
                loading: false,
                itemList
            })
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
     }

    render() {
        if (this.state.loading) return <div className="loader" style={{ margin: '10rem' }}><img src={loadingIcon} alt="loading" /></div>

        // return <div style={{ margin: '10rem auto', maxWidth: '150px' }}><img src={inProgressIcon} alt='In progress' /></div>
        
        return <div className='latest-wrapper'>
            {this.state.itemList.map((item) => 
                <Link className='latest-image-wrapper' key={`cont-${item.id}`} to={`/day/${item.day}`} title={DisplayService.getFormattedDateFromKey(item.day)}>
                    <div className='image-polaroid' key={`inn-${item.id}`}>
                        <Image key={`img-${item.id}`} data={item} />
                    </div>
                </Link>
                )}
            </div>
    }
}

export default Latest
