import React, { Component } from "react"
import Latest from "../components/latest"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { withAuthenticator } from "aws-amplify-react"
import Amplify from "aws-amplify"
import { config } from '../config'
import loadingIcon from '../images/loader.svg'
import CookieService from '../services/CookieService'

Amplify.configure(config)

class IndexPage extends Component {
  constructor () {
    super()
    this._isMounted = false

    this.state = {
      loading: true
    }
  }

  async componentDidMount() {
      this._isMounted = true

      const cookies = await CookieService.set() // eslint-disable-line no-unused-vars

      if (this._isMounted) {
          this.setState({
              loading: false
          })
      }
  }

  render() {
    let content = ''

    if (this.state.loading) {
      content = <div className="loader"><img src={loadingIcon} alt="loading" /></div>
    } else {
      content = <Latest />
    }
      
    return (
      <Layout menu="HOME">
        <SEO title="Home" />
        <h1>Accueil</h1>
        <div className="latest-block no-user-select">Dernières Additions</div>
        {content}
      </Layout>
    )
  }
}

export default withAuthenticator(IndexPage, {
  includeGreetings: false
 })
